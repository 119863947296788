const Env = { 
    // server: "http://localhost:8089", 
    // dashboard: "http://localhost:3001/",  

    Time:"Mon-Fri: 11am - 9pm",
    Email:"info@thevapegarage",
    facebook:"https://www.facebook.com/thevapegarage",
    twitter:"https://twitter.com",
    instagram:"https://www.instagram.com/thevapegarage",
    youtube:"https://www.youtube.com",
    tiktok:"https://www.tiktok.com",
    logo:"https://ik.imagekit.io/tdyftai86/Clietns/Untitled_design__4___1_-removebg-preview.png?updatedAt=1733944355022",
    businessName:"The Vape Garage",
    PhNum:"+44 7300 401810",
    address:"United Kingdom"
    } 
export default Env;